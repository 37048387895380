export default {
    homepage: 'Startseite',
    blogs: 'Blogs',
    blog: 'Blog',
    searchByName: 'nach Name suchen',
    categories: 'Kategorien',
    product: 'Produkt',
    reviews: 'Bewertungen',
    polices: 'Richtlinien',
    companies: 'Unternehmen',
    exhibitions: 'Ausstellungen',
    requests: 'Anfragen',
    request: 'Anfrage',
    services: 'Dienstleistungen',
    service: 'Dienstleistung',
    allCategories: 'Alle Kategorien',
    allServices: 'Alle Dienstleistungen',
    articles: 'Artikel',
    about: 'Über uns',
    contact: 'Kontakt',
    copyRight: 'Martofworld Alle Rechte vorbehalten',
    quickAccess: 'Sie können hier schnell auf die Seiten zugreifen, die Sie benötigen',
    quickMenu: 'Schnellmenü',
    noSubSerivce: 'Kein Unterdienst...',
    noSubCategory: 'Keine Unterkategorie...',
    contactUs: 'Kontaktieren Sie uns',

    
    // companies
    locationHere: 'Standort hier',
    chatNow: 'Jetzt chatten',
    noProductsFound: 'Keine Produkte gefunden.',
    seeDetails: 'Details ansehen',
    segments: 'Segmente',
    sendInquiry: 'Anfrage senden',
    message: 'Nachricht',
    send: 'Senden',
    keywords: 'Schlüsselwörter',
    certificates: 'Zertifikate',
    confirmingBanks: 'Bestätigende Banken',
    brands: 'Marken',
    address: 'Adresse',
    visitCompany: 'Unternehmen besuchen',
    selectCategory: 'Kategorie auswählen',
    subCategories: 'Unterkategorien',
    country: 'Land',
    countryName: 'Ländername',
    selectCountry: 'Land auswählen',
    selectSubCategory: 'Unterkategorie auswählen',
    cities: 'Städte',
    city: 'Stadt',
    logout: 'Abmelden',
    viewDashboard: 'Dashboard anzeigen',

    // contact
    yourName: 'Ihr Name',
    yourNameHere: 'Ihr Name hier...',
    yourMessageHere: 'Ihre Nachricht hier...',
    yourEmail: 'Ihre E-Mail',
    subject: 'Betreff',
    optional: 'Optional',
    submit: 'Absenden',
    emailAddress: 'E-Mail-Adresse',
    resetFilters: 'Filter zurücksetzen',
    noExhibitionsFound: 'Keine Ausstellungen gefunden',
    to: 'An',
    from: 'Von',
    type: 'Typ',
    noRequestsFound: 'Keine Anfragen gefunden',
    subjectHere: 'Betreff hier...',
    companyName: 'Firmenname',

    // homepage
    lastCompanies: 'Letzte Unternehmen',
    seeAllCategories: 'Alle Kategorien ansehen',
    companiesBy: 'Unternehmen nach',
    searchForCompany: 'Nach Unternehmen suchen',
    noResult: 'Kein Ergebnis',
    fetchingCompaniesWith: 'Abrufen von Unternehmen mit',
    projectsIdeas: 'Projektideen',
    tendersAnd: 'Ausschreibungen und',
    seeMore: 'Mehr anzeigen',
    requestTitle: 'Steigern Sie Ihre Ersparnisse auf neue Höhen!',
    purchaseRequests: 'Kaufanfragen',
    saleOffers: 'Verkaufsangebote',
    viewMore: 'Mehr anzeigen',
    searchStepTitle1: 'Vereinfachen Sie die Bestellung von der Suche bis zur Erfüllung,',
    searchStepTitle2: 'Alles an einem Ort',
    step1Title: 'Unternehmen suchen',
    step2Title: 'Beste Lieferanten finden',
    step3Title: 'Angebote einholen',
    step4Title: 'Deals abschließen',
    step5Title: 'In Ihrem Geschäft erfolgreich sein',

    // requests
    views: 'Ansichten',
    requestDetails: 'Anfragedetails',
    quantity: 'Menge',
    price: 'Preis',
    startingAt: 'Beginnend bei',
    endingAt: 'Endet bei',
    tags: 'Stichworte',
    contactInfo: 'Kontaktinformationen',

    // about page
    productsForSale: 'Produkte zum Verkauf',
    productsForSaleDesc: 'Eine große Anzahl von Herstellern und Unternehmen, die Millionen von Produkten herstellen',
    companiesEarnings: 'Unternehmensgewinne',
    companiesEarningsDesc: 'Millionen von Dollar Einnahmen für Unternehmen weltweit',
    growingBuyers: 'Wachsende Käufer',
    growingBuyersDesc: 'Kontinuierliches Wachstum von Unternehmen auf der ganzen Welt',
    aboutLastSectionTitle: 'Die Rolle von Mart Of World',
    aboutLastSectionDesc: 'Das Mart of World-Projekt ist ein B2B-Handelssystem, das Verkäuferunternehmen mit Kunden verbindet, um den Geschäfts- und Handelsumfang zwischen verschiedenen Unternehmen und Kunden zu erweitern. Das System repräsentiert somit einen Marktplatz für alle Unternehmen, ob sie nun Produkte oder Dienstleistungen suchen. Das Hauptziel ist es, eine Plattform zu bieten, die Unternehmen miteinander verbindet, sodass sie mühelos zusammenarbeiten können und Zugang zu neuen Märkten erhalten.',
    exploreMore: 'Mehr entdecken',
    aboutSecondSectionTitle: 'Mart of World besteht aus vielen Unternehmen, die in verschiedenen Bereichen des Handels, der Industrie und des Marketings tätig sind und den internationalen B2B-Handel zwischen Unternehmen erleichtern.',
    aboutMainGoal: 'Hauptziel',
    aboutMainGoalDesc: 'Das Hauptziel ist es, Unternehmen aus der ganzen Welt auf einer Plattform zusammenzubringen und ihnen die notwendigen Werkzeuge zur Verfügung zu stellen, um ihr Geschäft zu entwickeln und neue Märkte zu erschließen. Mart of World hat auch eine Online-Plattform geschaffen, die Hersteller, Unternehmen und Unternehmer mit Kunden aus der ganzen Welt verbindet, sodass sie ohne geografische Einschränkungen Geschäfte tätigen können.',
    aboutMartofWorld: 'Über Mart of World',
    internationalTrading: 'Internationaler Handel...',
    aboutMartofWorldDesc: 'Der B2B-Handel hat den internationalen Handel erleichtert und beschleunigt. Jetzt, online, können Menschen miteinander kommunizieren und ihre Arbeit erledigen, ohne sich anzustrengen. Dies hat zu einem schnellen Wachstum des internationalen Handels geführt und den Handel zwischen Unternehmen zu einem der wichtigsten Geschäftsmethoden gemacht.',
    B2B: 'B2B',

    // auth pages
    enterEmailToGetCode: 'Geben Sie Ihre E-Mail ein, um den Code zum Zurücksetzen des Passworts zu erhalten',
    getCode: 'Code erhalten',
    login: 'Anmelden',
    backTo: 'Zurück zu',
    password: 'Passwort',
    email: 'E-Mail',
    name: 'Name',
    phone: 'Telefon',
    lostPassword: 'Passwort vergessen?',
    logIn: 'Einloggen',
    dontHaveAccount: 'Kein Konto?',
    registerNow: 'Jetzt registrieren',
    alreadyHaveAccount: 'Bereits ein Konto?',
    resetPassword: 'Passwort zurücksetzen',
    confirmPassword: 'Passwort bestätigen',
    congrats: 'Herzlichen Glückwunsch...',
    youCanLogin: 'Ihre E-Mail wurde verifiziert, Sie können sich jetzt einloggen',
    goToLogin: 'Zur Anmeldung',
    loading: 'Wird geladen...',
    verifyEmail: 'E-Mail bestätigen',
    register: 'Registrieren',

    // meta
    metaDescription_Homepage: 'metaDescription_Startseite',
    metaKeywords_Homepage: 'metaKeywords_Startseite',

    metaDescription_About: 'metaDescription_ÜberUns',
    metaKeywords_About: 'metaKeywords_ÜberUns',

    metaDescription_Blogs: 'metaDescription_Blogs',
    metaKeywords_Blogs: 'metaKeywords_Blogs',

    metaDescription_Categories: 'metaDescription_Kategorien',
    metaKeywords_Categories: 'metaKeywords_Kategorien',

    metaDescription_Companies: 'metaDescription_Unternehmen',
    metaKeywords_Companies: 'metaKeywords_Unternehmen',

    metaDescription_Contact: 'metaDescription_Kontakt',
    metaKeywords_Contact: 'metaKeywords_Kontakt',

    metaDescription_Exhibitations: 'metaDescription_Ausstellungen',
    metaKeywords_Exhibitations: 'metaKeywords_Ausstellungen',

    metaDescription_Packages: 'metaDescription_Pakete',
    metaKeywords_Packages: 'metaKeywords_Pakete',

    metaDescription_Requests: 'metaDescription_Anfragen',
    metaKeywords_Requests: 'metaKeywords_Anfragen',

    metaDescription_Service: 'metaDescription_Dienstleistung',
    metaKeywords_Service: 'metaKeywords_Dienstleistung',
};
